import React from 'react';

const SearchBar = () => {
    const containerStyle = {
        height: '6vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Arial, sans-serif',
        marginBottom: '30px', // Adjust the value as needed
    };

    const searchBarStyle = {
        display: 'flex',
        alignItems: 'center',
    };

    const inputStyle = {
        padding: '4px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        marginRight: '10px',
    };

    const buttonStyle = {
        padding: '2px 6px',
        fontSize: '14px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        backgroundColor: '#f0f0f0',
        cursor: 'pointer',
        backgroundColor: '#e85b23',
        color: '#fff', // Ensure the button text is white for better contrast
    };

    const buttonHoverStyle = {
        backgroundColor: '#e0e0e0',
    };

    const [hover, setHover] = React.useState(false);

    return (
        <div style={containerStyle}>
            <div style={searchBarStyle}>
                <input type="text" placeholder="Search..." style={inputStyle} />
                <button 
                    type="submit" 
                    style={hover ? { ...buttonStyle, ...buttonHoverStyle } : buttonStyle}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    Search
                </button>
            </div>
        </div>
    );
}

export default SearchBar;

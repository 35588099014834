import React from 'react';
import Hero from '../containers/Hero';

 
const Home = () => {
    return (
         <>
            <Hero />
        </>
    )
}

export default Home

import { useEffect } from 'react';
import Routes from './routes';
import loadjs from 'loadjs';
 


const App = () =>  <Routes />
 

export default App



